import { Box, Link, Stack } from '@mui/material';
import { FC, useMemo, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Avatar from '@mui/material/Avatar';
import TableContainer from '@mui/material/TableContainer';
import { Link as RouterLink } from 'react-router-dom';
import { UserProductWithAccessDto } from '@h2know-how/identity-sdk';
import Loader from 'src/ui/Loader';
import Search from 'src/ui/Search';
import { FetchErrorResult, NoSearchResult, Result } from 'src/ui/Result';
import { UserAPI } from 'src/services/api/UserAPI';
import { HeaderCell } from 'src/features/admin/components/HeaderCell';
import { HeaderSortLabel } from 'src/features/admin/components/HeaderSortLabel';
import { TableLoadingSkeleton } from 'src/features/admin/components/LoadingSkeleton';
import { useSortByProperties } from 'src/hooks/useSortByProperties';
import { useAppSelector } from 'src/store/useAppSelector';

export const ProductsPage: FC = () => {
  const { data: products, isLoading, error } = UserAPI.useGetProductsWithAccess();
  const user = useAppSelector((state) => state.auth.user) ?? undefined;

  const isSuperAdmin = user?.isSuperAdmin === 'True';

  const [searchValue, setSearchValue] = useState('');

  const adminProducts = useMemo(
    () => products?.filter((product) => product.isProductAdmin || isSuperAdmin),
    [isSuperAdmin, products]
  );

  const filteredProducts = useMemo(
    () => adminProducts?.filter((product) => product?.name?.toLowerCase()?.includes(searchValue?.toLowerCase())),
    [adminProducts, searchValue]
  );

  const {
    sorted: filteredAndSortedProducts,
    sort,
    getDirection,
  } = useSortByProperties<UserProductWithAccessDto>(filteredProducts);

  const isErrorState = !isLoading && error && !products;
  const isNoDataState = !isLoading && !error && !adminProducts?.length;
  const isNoSearchResultState = !isErrorState && !isNoDataState && !!searchValue && !filteredAndSortedProducts?.length;
  const isAbnormalState = isErrorState || isNoSearchResultState || isNoDataState;

  if (isErrorState) {
    return <FetchErrorResult />;
  }

  if (isLoading) return <Loader flex={1} />;

  if (!products) return null;

  return (
    <>
      <Stack padding={2}>
        <Search
          label="Search Products"
          name="searchProducts"
          filterValue={searchValue}
          onFilterValueChange={setSearchValue}
        />
      </Stack>
      <TableContainer component={Paper}>
        <Table aria-label="Table of available products">
          <TableHead>
            <TableRow>
              <HeaderCell>
                <HeaderSortLabel active direction={getDirection(['name'])} onClick={() => sort(['name'])}>
                  PRODUCT{filteredAndSortedProducts ? ` (${filteredAndSortedProducts.length})` : ''}
                </HeaderSortLabel>
              </HeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isAbnormalState && (
              <TableRow>
                <TableCell>
                  {isNoDataState && <Result severity="info" title="There are no available products" />}
                  {isNoSearchResultState && <NoSearchResult />}
                  {isErrorState && <FetchErrorResult />}
                </TableCell>
              </TableRow>
            )}

            {isLoading && <TableLoadingSkeleton columns={1} />}

            {filteredAndSortedProducts &&
              filteredAndSortedProducts?.map((product) => {
                return (
                  <TableRow key={`${product.name}`} sx={{ '&:nth-of-type(odd)': { backgroundColor: 'divider' } }}>
                    <TableCell>
                      <Box display="flex" alignItems="center" columnGap={1}>
                        <Avatar>{product.name[0]}</Avatar>
                        <Link
                          to={`${product.productId}`}
                          component={RouterLink}
                          underline="always"
                          sx={{ color: 'text.primary' }}
                        >
                          {product?.name}
                        </Link>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
