import { AppFullRoutePath, AppPage } from 'src/router';

type Page = {
  linkName: string;
  linkUrl: string;
  index: number;
};

const pages: Page[] = [
  {
    linkName: 'Home',
    linkUrl: AppFullRoutePath[AppPage.Home],
    index: 0,
  },
  {
    linkName: 'Marketplace',
    linkUrl: AppFullRoutePath[AppPage.MarketPlace],
    index: 1,
  },
  {
    linkName: 'Products',
    linkUrl: AppFullRoutePath[AppPage.AdminProducts],
    index: 2,
  },
];

export default pages;
